import React from "react";
import Main from "../../components/layouts/Main";
import {graphql} from "gatsby";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Box, Container, SimpleGrid, GridItem} from "@chakra-ui/react";
import Seo from "../../components/SEO";
import SmallNav from "../../components/smallNav";
import {DEFAULT_IMAGE} from "../../static/constants";
import ProductCardforEvents from "../../components/productcard/ProductCardforEvents";
import usePrismicFinancialEducation from "../../hooks/usePrismicFinancialEducation";

const AllFinancialEducationEvents = () => {
    const { language } = useTranslation();
    const data = usePrismicFinancialEducation()
    return (
        <Main>
            <Box overflow="hidden">
                <Container
                    px="5"
                    maxW="7xl">
                    <Seo
                        title={data.title}
                        description={data.title}
                        link={`https://www.fsm.am/${language}/events`}
                    />
                    <SmallNav >
                        {data.title}
                    </SmallNav>
                    <SimpleGrid columns={[1,1,3,3]} gap={6} mb={10} mt={{base: '1.875rem', sm: "10"}}>
                        {
                            data.slideData?.map((slide, idx) => (
                                <GridItem key={`events-${slide.title}-${idx}`} width="100%">
                                    <ProductCardforEvents
                                        title={slide.title}
                                        image={{file: {url: slide.image ? slide.image : DEFAULT_IMAGE}}}
                                        description={slide.description}
                                        idNews={slide.id && slide.id}
                                        date
                                        cardDate={slide.date && slide.date}
                                        mainButton
                                        product
                                        borderRadius={24}
                                        hrefNews="financial-education-events"
                                    />
                                </GridItem>
                            ))
                        }
                    </SimpleGrid>
                </Container>
            </Box>
        </Main>
    )
}

export default AllFinancialEducationEvents

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;